<template>

    <a-layout class="yj-conten-div">
        <a-layout-content>
            <div class="yj-conten" style="background:#fff;padding:20px 20px 0;min-height:calc(100% - 48px)">
                <a-table class="yj-table-img"
                         bordered :columns="columns2"
                         :data-source="AwardwinningList"
                         :pagination="pagination"
                         @change="pagerChange">
                    <span slot="imgs" slot-scope="text">
                        <img class="imgPreview" :src="text" alt="" />
                    </span>
                <span slot="RedPrice" slot-scope="text,record">
                    {{record.RewardMoney==''?'':'红包：'+record.RewardMoney}}
                    {{record.PoolName==null?'':'优惠券：'+record.PoolName}}
                </span>

                    <a-button type="primary" @click="taskDetailList" style="display:none"></a-button>
                </a-table>

            </div>
        </a-layout-content>
    </a-layout>

</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns2: [
                    {
                        title: "头像",
                        dataIndex: "UHeadImg",
                        align: "center",
                        scopedSlots: { customRender: "imgs" },
                        width: 100
                    },
                    {
                        title: "昵称",
                        dataIndex: "Name",
                        width: 200,
                        ellipsis: true,
                        scopedSlots: { customRender: "Name" }

                    },
                    {
                        title: "电话",
                        dataIndex: "Phone",
                    },
                    {
                        title: "时间",
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.ReceivePrizeTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "摘要",
                        scopedSlots: { customRender: "RedPrice" },
                    },

                ],
                taskDetaildata: [],
                AwardwinningList: [],//领奖列表
                name: "",
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
            };
        },
        methods: {
          
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item.current;
                self.taskDetailList();
            },
            taskDetailList: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetAwardWinningData",
                    data: {
                        pageIndex: self.pagination.current,
                        TurnID: self.ID,
                    },
                    OnSuccess: function (data) {
                        self.AwardwinningList = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },

        },
        mounted() {
            var self = this;
            self.ID = self.$route.query.TurnID;
            self.taskDetailList();
        },
     
        computed: {},
        components: {}
    };
</script>
<style scoped>
    .imgPreview {
        border-radius: 4px;
        width: 50px;
        height: 50px;
    }
</style>